import React from "react";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { motion, spring } from "framer-motion";
const Hero = () => {
  return (
    <section className="hero-warpper">
      <div className=" innerWidth flexCenter hero-container">
        {/* left section  */}

        <div className="hero-left flexColStart">
          <div className="hero-title">
            <div className="orange-circle" />
            <motion.h1
              initial={{ y: "2rem", opacity: 0 }}
              animate={{ y: "0rem", opacity: 1 }}
              transition={{ duration: 2, type: "easeIn" }}
            >
              Discover<br></br> Most Suitable<br></br> Property
            </motion.h1>
          </div>
          <div className="hero-des flexColStart">
            <span className="secondaryText">
              Find a variety of properties that suit you very easilty
            </span>
            <span className="secondaryText">
              Forget all difficulties in finding a residence for you
            </span>
          </div>
          <div className="search-bar flexCenter">
            <FontAwesomeIcon
              icon={faLocationDot}
              color="var(--blue)"
              style={{ fontSize: "25px" }}
            />
            <input type="text" />
            <button className="button">Search</button>
          </div>
          <div className="stats flexCenter">
            <div className="stat flexColCenter">
              <span>
                <CountUp start={8800} end={9000} duration={4} />
                <span>+</span>
              </span>
              <span className="secondaryText">Premium Product</span>
            </div>
            <div className="stat flexColCenter">
              <span>
                <CountUp start={1950} end={2000} duration={4} />
                <span>+</span>
              </span>
              <span className="secondaryText">Happy Customer</span>
            </div>
            <div className="stat flexColCenter">
              <span>
                <CountUp end={28} />
                <span>+</span>
              </span>
              <span className="secondaryText">Awards Winning</span>
            </div>
          </div>
        </div>

        {/* right section  */}
        <div className="hero-right flexCenter">
          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: "0rem", opacity: 1 }}
            transition={{ duration: 2, type: "easeIn" }}
            className="image-container"
          >
            <img src="./assets/hero-image.png" alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
