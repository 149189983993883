import React from "react";
import "./Contact.css";
import { MdCall } from "react-icons/md";
import { BsFill0CircleFill, BsFillChatDotsFill } from "react-icons/bs";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";
const Contact = () => {
  return (
    <section className="c-warpper" id="contact">
      <div className="c-container paddings innerWidth flexCenter">
        {/* left side  */}
        <div className="c-left flexColStart">
          <span className="orangeText">Our Contact Us</span>
          <span className="primaryText">Easy to contact us</span>
          <span className="secondaryText">
            We always ready to help by providijng the best services for you. We
            beleive a good blace to live can make your life better
          </span>
          <div className="flexColStart contact-modes">
            {/* first row  */}
            <div className="flexStart row">
              {/* first mode  */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="icon flexCenter">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="button flexColCenter">Call Now</div>
              </div>
              {/* second mode  */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="icon flexCenter">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Chat</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="button flexColCenter">Chat Now</div>
              </div>
            </div>
            {/* second row  */}
            <div className="flexStart row">
              {/* first mode  */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="icon flexCenter">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Video Call</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="button flexColCenter">Video Call Now</div>
              </div>
              {/* second mode  */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="icon flexCenter">
                    <HiChatBubbleBottomCenter size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Meesage</span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="button flexColCenter">Message Now</div>
              </div>
            </div>
          </div>
        </div>
        {/* right side  */}
        <div className="c-right">
          <div className="image-container">
            <img src="./assets/contact.jpg" alt="Contact image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
